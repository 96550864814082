window.$window = $(window);
window.$body = $('body');
window.page = $body.data('page');
window.$header = $('#header');
window.$wrapper = $('#wrapper');
window.spaceToHeaderFixed;
window.language = $('html').attr('lang');
window.timeTransitionDefault = 350;
window.texts = [];

texts['pt-BR'] = {
  quantity: 'quantidade',
  price: 'preço',
  cart_empty: 'O seu carrinho está vazio',
  go_product: 'Ir para a página do produto',
  btn_edit_cart: 'Editar pedido',
  btn_checkout: 'Finalizar pedido',
  btn_success: 'Adicionado!',
  errorsTxt: {
    withoutSku: 'Selecione um atributo para o produto',
  },
};

function getText(key, subKey) {
  if (typeof subKey == 'undefined') {
    return texts[language][key];
  } else {
    return texts[language][key][subKey];
  }
}

window.store = {
  config: {
    // Seletor do botão de comprar
    selectorBtnBuy: '[data-action="add-cart"]',
    selectorBtnBuyAssinatura: '[data-action="add-cart-assinatura"]',
    // Seletor do elemento que engloba um produto
    selectorWrapperProduct: '[data-box-produto]',
    selectorWrapperProductAssinatura: '[data-box-produto-assinatura]',
    selectorFormProduct: '[data-form-product]',
    selectorFormProductAssinatura: '[data-form-product-assinatura]',
    // Se a store tem versão internacional ou não
    hasInternacionalVersion: false,
    errorsTxt: {
      withoutSku: getText('errorsTxt', 'withoutSku'),
    },
    // configuração de comportamento ao add um produto
    addToCartOpenCartpopup: true,
    addToCartOpenCartpopupMobile: true,
    cartCount: {
      selector: '[data-cart-count]',
      attr: '[data-cart-count-attr]',
      text: '[data-cart-item-text]',
    },
    priceProds: {
      selector: '[data-update-price]',
      attr: 'update-price',
    }
  },
  formatMoney: function (value) {
    // FORMATA UM VALOR
    return (
      'R$ ' +
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
    );
  },
  validateQuantity: function (_val) {
    // VALIDA SE A QUANTIDADE INFORMADA É UM NÚMERO
    if (!isNaN(_val)) {
      if (parseInt(_val) > 0) {
        return true;
      }
    }

    return false;
  },
  getClearNumber: function (_val) {
    // RETORNA UM NÚMERO LIMPO COMO INT
    if (!isNaN(_val)) {
      clearNumber = parseInt(_val);

      return clearNumber;
    }

    return false;
  },
  validateFormProduct: function (_$form) {
    // VALIDA O FORM DE PRODUTO PARA VER SE O PRODUTO PODE SER ADICIONADO
    var $parent = _$form.closest('[data-box-produto]');
    var $btnComprar = _$form.find('[data-action="add-cart"]');
    var validated = true;
    var errorsTxt = [];

    if ($btnComprar.data('available') == 'false') {
      validated = false;
      errorsTxt.push('');
    } else {
      //  verifica se o sku foi selecionado
      if (_$form.find('[name="sku"]').val() == '') {
        validated = false;
        errorsTxt.push('Selecione um atributo para o produto');
      }

      //  verifica se a quantidade é válida
      // console.log(_$form.find('input[name="quantity"]').val());
      if (_$form.find('input[name="quantity"]').val() <= 0) {
        // console.log('qtd invalida');
        validated = false;
        errorsTxt.push('Quantidade indisponível');
      }
    }

    return { validated: validated, errors: errorsTxt };
  },
  setRespValidateProduct: function (_resp, _$form, _$boxResponse) {
    // var $boxResult = _$form.find('.resp-validate');
    var $boxResult = _$boxResponse;
    var htmlErrors = '';

    if (_resp.validated) {
      $boxResult.empty();
    } else {
      for (var i = _resp.errors.length - 1; i >= 0; i--) {
        htmlErrors += '<span class="msg error">' + _resp.errors[i] + '</span>';
      }

      $boxResult.html(htmlErrors);
    }
  },
  addItemResultDefault: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuy);

      $btnComprar.addClass('success').html('Produto adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlCart;
      }, 150);
    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof _$boxResponse != 'undefined' && _$boxResponse.length > 0) {
        // console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  addItemResultDefaultAssinatura: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuyAssinatura);

      $btnComprar.addClass('success').html('Adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlAssinaturaCart;
      }, 150);
    } else if (_typeResult == 'erro-adicionar') {
      if (typeof _result == 'object') {
        var errorText = _result;
      } else {
        var errorText = JSON.parse(_result);
      }

      if (typeof _$boxResponse != 'undefined' && _$boxResponse.length > 0) {
        // console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);
      }
    }
  },
  addItem: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find('[data-action="add-cart"]');
    var urlAdd = '/carrinho/adicionar';
    var urlUpdate = '/carrinho';
    var url = urlAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find('[data-form-product] .msg-response:not(.resp-validate)');

    // console.info('addItem');
    // console.info('addItem', _$form.serializeArray());

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: form,
        beforeSend: function () {
          $btnComprar.addClass('adding');
        },
      })
      .done(function (resp) {
        // console.log('success');
        // console.info(resp);

        if (typeof template_store.addItemResult == 'function') {
          template_store.addItemResult(_$form, 'produto-adicionado', resp, $boxResponse);
        } else {
          template_store.addItemResultDefault(_$form, 'produto-adicionado', resp, $boxResponse);
        }
      })
      .fail(function (resp) {
        // console.log('error');
        // console.error(resp);
        // console.error(resp.responseText);
        // console.error(resp.responseText.error);

        if (typeof template_store.addItemResult == 'function') {
          template_store.addItemResult(_$form, 'erro-adicionar', resp, $boxResponse);
        } else {
          template_store.addItemResultDefault(_$form, 'erro-adicionar', resp, $boxResponse);
        }
      })
      .always(function () {
        // console.log('complete');
        $btnComprar.removeClass('adding');
      });
    }
  },
  addItemAssinatura: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find(store.config.selectorBtnBuyAssinatura);
    var url = urlAssinaturaCartAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find(
      store.config.selectorFormProductAssinatura + ' .msg-response:not(.resp-validate)'
    );
    var data = {};

    data.subscription_item = {
      quantity: _$form.find('[name="quantity"]').val(),
      sku: _$form.find('[name="sku"]').val(),
      extra: {
        vindi_plan_id: _$form.find('[name="extra[vindi_plan_id]"]').val(),
      },
    };

    // console.info('addItemAssinatura');
    // console.info('addItemAssinatura', data);
    // console.info('addItemAssinatura', JSON.stringify(data));

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        // dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        beforeSend: function () {
          $btnComprar.addClass('adding');
        },
      })
        .done(function (resp) {
          // console.log('success');
          // console.info(resp);

          if (typeof template_store.addItemResult == 'function') {
            template_store.addItemResult(_$form, 'produto-adicionado', resp, $boxResponse);
          } else {
            template_store.addItemResultDefaultAssinatura(
              _$form,
              'produto-adicionado',
              resp,
              $boxResponse
            );
          }
        })
        .fail(function (xhr, status, error) {
          // console.log('error');
          // console.error(xhr);
          // console.error(status);
          // console.error(error);

          var resp = {
            error: 'Houve um problema ao adicionar. Tente mais tarde ou entre em contato.',
          };

          if (typeof template_store.addItemResult == 'function') {
            template_store.addItemResult(_$form, 'erro-adicionar', resp, $boxResponse);
          } else {
            template_store.addItemResultDefaultAssinatura(
              _$form,
              'erro-adicionar',
              resp,
              $boxResponse
            );
          }
        })
        .always(function () {
          // console.log('complete');
          $btnComprar.removeClass('adding');
        });
    }
  },
  deleteItem: function (_itemId, _$item, removeItemResult) {
    var _this = this;

    if (!_$item.hasClass('removing')) {
      $.ajax({
        url: '/carrinho',
        type: 'POST',
        dataType: 'json',
        data: {
          _method: 'delete',
          item_id: _itemId,
        },
        beforeSend: function () {
          _$item.addClass('removing');
        },
      })
        .done(function (_cart) {
          // console.log('success');

          if ($body.width() > 768) {
            if (store.config.addToCartOpenCartpopup) {
              template_store.headerCart.loadCart(_cart);
              template_store.headerCart.hide();
            }
          } else {
            if (store.config.addToCartOpenCartpopupMobile) {
              template_store.headerCart.loadCart(_cart);
              template_store.headerCart.hide();
            }
          }

          if (typeof removeItemResult == 'function') {
            removeItemResult(_$item, _cart);
          }
        })
        .fail(function (error) {
          // console.log('erro ao remover', error.responseText);
        })
        .always(function () {
          _$item.removeClass('removing');
        });
    }
  },
  setMsgResponse: function (_msg, _type, _$parent) {
    /*
      O tipo pode conter um dos 3 valores: success, warning, error ou CLEAR.
      O type CLEAR limpa as mensagens e faz sumir a mensagem
    */

    // Verifica se não há mais de uma mensagem
    // caso tenha, gera um html para cada mensagem
    if (Array.isArray(_msg)) {
      var msgArr = _msg;
      _msg = '';
      for (var i = msgArr.length - 1; i >= 0; i--) {
        _msg += '<span class="msg error">' + msgArr[i] + '</span>';
      }
    }

    _$parent
      .find('[data-msg-retorno]')
      .removeClass('success')
      .removeClass('warning')
      .removeClass('error');

    _$parent.find('[data-msg-retorno] [data-msg]').empty();

    if (_type != 'clear') {
      _$parent.find('[data-msg-retorno]').addClass(_type);
      _$parent.find('[data-msg-retorno] [data-msg]').html(_msg);
    }
  },
  updateCartCount: function (_itemsCount) {
    var _this = this;

    // $(_this.cartCount.selector).empty().text(_itemsCount);

    $(_this.config.cartCount.attr).data('cart-count-attr', _itemsCount);
    $(_this.config.cartCount.attr).attr('data-cart-count-attr', _itemsCount);

    $(_this.config.cartCount.attr).html(_itemsCount);
    $(_this.config.cartCount.text).html(_itemsCount);
    $(_this.config.cartCount.textLabel).html(_itemsCount > 1 ? 'itens' : 'item');

    if (_itemsCount > 0) {
      $('[data-cart-body]').parent().addClass('active');
    } else {
      $('[data-cart-body]').parent().removeClass('active');
    }
  },
  getCartCount: function () {
    async function getCartItens() {
      let itens;

      try {
        itens = await $.ajax({
          url: '/carrinho/itens',
          type: 'GET',
          dataType: 'text',
        });

        return itens;
      } catch (error) {
        // console.error(error);
      }
    }

    if ($(store.config.cartCount.selector).length > 0) {
      getCartItens().then((total) => {
        var itens = Number.isInteger(parseInt(total));
        if (itens) {
          $(store.config.cartCount.attr).attr('data-cart-count-attr', total);
          $(store.config.cartCount.selector).html(total);
        }
      });
    }
  },
  submitForm: function (_$form) {
    _$form.find('[name="reply_to"]').val(_$form.find('[name="email"]').val());

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: '/webform',
        type: 'POST',
        data: _$form.serialize(),
        beforeSend: function () {
          _$form.addClass('sending');
        },
      })
        .done(function () {
          // console.info('foi');
          // store.setMsgResponse('Mensagem enviada com sucesso!', 'success', _$form);
          _$form.find('[data-msg-retorno] [data-msg-success]').addClass('visible');
          _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');
          _$form.find('button[type=submit]').text('Enviado');

          setTimeout(function () {
            _$form.find('button[type=submit]').text('Enviar');
          }, 3500);
          _$form[0].reset();
        })
        .fail(function () {
          // console.info('foi, mas deu ruim');
          _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
          _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
          _$form.find('button[type=submit]').text('Falha ao enviar');
          setTimeout(function () {
            _$form.find('button[type=submit]').text('Enviar');
          }, 3500);
          // store.setMsgResponse('Houve um problema ao enviar sua mensagem, tente novamente! Ou entre em contato conosco pelo telefone (21) 99644-6925 ', 'error', $form);
        })
        .always(function () {
          // console.log('complete');
          _$form.removeClass('sending');
        });
    }
  },
  getPriceProd: function () {

    var selector = store.config.priceProds.selector;
    var attr = store.config.priceProds.attr;

    if ($(selector).length > 0) {

      $(selector).each(function(index, value) {
        var $this = $(this);
        var prodId = $(this).data(attr);
        var url = '/produto/preco/' + prodId;

        if(prodId != '' && prodId != null) {
          $.ajax({
            url: url,
            type: 'GET',
          })
          .done(function(resp) {
            // console.info(resp);
            $this.html(resp);

            if($('[data-form-product-menu-especial]').length > 0){
              template_store.product.menuEspecial.updatePrice()
            }
          })
          .fail(function(resp) {
            // console.error(resp);
          });
        }
      });

    }
  },
  login: {
    validateLogin: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe a senha!');
      }

      return { validated: validated, errors: errorsTxt };
    },
    validateRegister: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe uma senha!');
      }

      if (_$form.find('[name="password"]').val() != '') {
        var pass = _$form.find('[name="password"]').val();
        var confirmationPass = _$form.find('[name="password_confirmation"]').val();

        if (pass != confirmationPass) {
          validated = false;
          errorsTxt.push('Senha e confirmação são diferentes!');
        }
      }

      return { validated: validated, errors: errorsTxt };
    },
    validateRecover: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      return { validated: validated, errors: errorsTxt };
    },
    logar: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/entrar',
          type: 'POST',
          dataType: 'json',
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          },
        })
          .done(function (resp) {
            // console.log('success', resp);

            if (resp.error) {
              store.setMsgResponse(resp.error, 'error', _$form);
            }

            if (resp.client) {
              window.location.href = resp.redirect_to;
            }
          })
          .fail(function (resp) {
            // console.error('error', error.responseText);
          })
          .always(function () {
            // console.log('complete');
            _$form.removeClass('sending');
          });
      }
    },
    recoverPassord: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/recuperar_senha',
          type: 'POST',
          dataType: 'json',
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          },
        })
          .done(function (resp) {
            // console.log('success', resp);

            if (resp.error) {
              store.setMsgResponse(resp.error, 'error', _$form);
            }

            if (resp.ok) {
              store.setMsgResponse(resp.ok, 'success', _$form);
            }

            /*if (resp.client) {
              window.location.href = resp.redirect_to;
            }*/
          })
          .fail(function (resp) {
            // console.error('error', error.responseText);
          })
          .always(function () {
            // console.log('complete');
            _$form.removeClass('sending');
          });
      }
    },
  },
};

window.template_store = {
  setMenuMobile: function () {
    $("#menu-mobile").mmenu({
      slidingSubmenus: true,
      extensions: [
        "pagedim-black",
        "position-front"
      ],
      navbar: {
        add: true
      }
    },
    {
      offCanvas: {
        pageSelector: "#wrapper"
      }
    });
    var mmenu = $("#menu-mobile").data('mmenu');
    $('[data-action="open-menu-mobile"]').on('click', function(event) {
      event.preventDefault();
      if ($("#menu-mobile").hasClass('mm-opened')) {
        mmenu.close();
      } else {
        mmenu.open();
      }
    });
  },
  /* 
    Manda um ajax para o form de avise-me e também para um form da plataforma para o cliente receber um email
  */
  submitFormNotify: function (_$form) {
    var formData = _$form.serialize();

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: _$form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: formData,
        beforeSend: function () {
          _$form.addClass('sending');
        },
      })
        .done(function (resp) {
          // console.log('success');
          // console.info(resp);

          var msgSuccess = 'Você receberá um e-mail quando o produto estiver disponível';

          if (resp.error) {
            _$form.find('[data-msg-retorno] [data-msg-error]').html(resp.error);
            _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
            _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
          } else {
            // Envia um email para o cliente saber que o usuário solicitou o avise-me
            $.ajax({
              url: '/webform',
              type: 'POST',
              data: {
                key: _$form.find('[name="key"]').val(),
                reply_to: _$form.find('[name="email"]').val(),
                email: _$form.find('[name="email"]').val(),
                product_name: _$form.find('[name="nome"]').val(),
                referencia: _$form.find('[name="referencia"]').val(),
                sku: _$form.find('[name="sku"]').val(),
              },
            })
              .done(function (resp) {
                _$form.find('[data-msg-retorno] [data-msg-success]').addClass('visible');
                _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');

                _$form[0].reset();
              })
              .fail(function (error) {
                _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
                _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
              });
          }
        })
        .fail(function (resp) {
          // console.log('error');
          // console.error(resp);
          // console.error(resp.responseText);
          // console.error(resp.responseText.error);

          var errorText = JSON.parse(resp.responseText);
          // console.info(errorText);

          _$form.find('[data-msg-retorno] [data-msg-error]').html(errorText.error);
          _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
          _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
        })
        .always(function () {
          _$form.removeClass('sending');
        });
    }
  },
  /* 
    Função que executa o comportamento de quando o produto é adicionado no carrinho
  */
  addItemResult: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this;

    // console.log('_typeResult', _typeResult);

    if (document.location.search.includes("quickview")) {
      // console.log('oie')
      var parentWindow = window.parent;
      parentWindow.postMessage(["produto-adicionado-quick-view", _result], "*");
    } else if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(store.config.selectorBtnBuy);

      $btnComprar.addClass('success').html(getText(language));

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      if ($body.width() > 768) {
        if (store.config.addToCartOpenCartpopup) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      } else {
        if (store.config.addToCartOpenCartpopupMobile) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      }
    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof _$boxResponse != 'undefined' && _$boxResponse.length > 0) {
        $('html,body').animate(
          {
            scrollTop: _$boxResponse.offset().top,
          },
          '250'
        );

        // console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  headerCart: {
    initDrawer: function () {
      var button = document.getElementById('bt-drawer');
      if (button) {
        button.addEventListener('click', window.componentCartDrawer.toggle);
      }
    },
    loadCart: function (total) {
      // console.log('headerCart.loadCart')
      store.updateCartCount(total.items_count);
    },
    show: function () {
      // console.log('headerCart.show');
      window.componentCartDrawer.open();
    },

    waitElement: function (selector) {
      return new Promise(function (resolve) {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }
        var observer = new MutationObserver(function(mutations) {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
    },
    productsMinQuantity() {
      const _this = this;
      // Apenas se houverem produtos com quantidade mínima cadastrados
      if (window._productsMinQuantity.length > 0) {
        const minList = window._productsMinQuantity;
        // Aguarda inserção do componente de carrinho no DOM
        _this.waitElement('.vnda-cart-drawer').then(cartDrawer => {
          // Observa alterações no carrinho
          const observer = new MutationObserver(mutation => {
            // Verifica se há nomes não verificados no carrinho
            if (cartDrawer.querySelector('.cart-item .cart-list-item-name a:not(.-ready)')) {
              console.info('updating cart...');
              // Seleciona o nome dos produtos no carrinho
              let names = cartDrawer.querySelectorAll('.cart-item .cart-list-item-name a');
              if (names.length > 0) names.forEach(name => {
                name.classList.add('-ready');
                // Verifica se o nome está na lista de produtos com quantidade mínima
                minList.forEach(p => {
                  if (p.name.replace('  ', ' ') == name.innerText.replace('  ', ' ')) {
                    // Remove seletores de quantidade do produto
                    let cartItem = name.closest('.cart-item');
                    cartItem.classList.add('-no-quantity');
                  }
                });
              });
            }
          });
          // Inicializa observação do carrinho
          observer.observe(cartDrawer, { childList: true, subtree: true });
        });
      }
    },

  },
  /*
    Debounce retorna uma função que enquanto continuar sendo chamada não é executada
    A função só será executada quando para de ser chamada por N milisegundos
    Útil para melhorar a performance de códigos que são executados muitas vezes por segundo, como o $(window).resize()

    Ex:
    
    $(window).resize(debounce(function() {
      // código a ser executado
    }, 500))
    
    No exemplo acima a função só será executada 500ms depois do último resize
    Abra o link abaixo e redimensione a janela branca e acompanhe o output do console
    Exemplo codepen: https://codepen.io/valkervieira/pen/oNgqyWY

    Um caso comum de uso é em lojas onde a seleção de um filtro na página de tag recarrega automáticamente a página
    Com o debounce o usuário pode escolher vários filtros rapidamente e a página só recarrega quando parar de escolher
  */
  debounce: function (func, wait, immediate = true) {
    var timeout;

    return function () {
      var context = this,
        args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  },
  /*
    Throttle diminui a frequencia que uma função é executada
    Enquanto no debounce a função só é executada quando para de ser chamada, no throttle ela
    continua sendo executada só que em um intervalo mínimo de N milisegundos (default = 250)

    Ex:

    $(window).resize(throttle() {
      // código a ser executado
    }, 500)

    No exemplo acima a função resize é chamada várias vezes por segundo mas só é executada 1 vez a cada 500ms
    Abra o link abaixo, redimensione a janela branca e acompanhe o console
    Exemplo codepen: https://codepen.io/valkervieira/pen/yLyKEPW

    Um caso comum de uso é checar se o scroll passou de um determinado ponto, para fixar um header ou alterar algum elemento do DOM
  */
  throttle: function (fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;

      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  },
  dropdownHeaderItens: function() {

    var $menu = $('#menu-desk');

    if ($menu.length > 0){
      function activateSubmenu(row) {
        var $row = $(row),
            submenuId = $row.data('submenuId'),
            $submenu = $('#' + submenuId);
  
        //console.log($submenu)
        $submenu.addClass('active');
        // Keep the currently activated row's highlighted look
        $row.find('.menu-title').addClass('maintainHover');
      }
      
      function deactivateSubmenu(row) {
        var $row = $(row),
            submenuId = $row.data('submenuId'),
            $submenu = $('#' + submenuId);
  
        $submenu.removeClass('active');
        // Hide the submenu and remove the row's highlighted look
        $row.find('.menu-title').removeClass('maintainHover');
      }
  
      $menu.menuAim({
        activate: activateSubmenu,
        deactivate: deactivateSubmenu
      });
  
      // remove os itens ativos ao fechar o menu
      var menu = document.getElementById('menu-desk');
      menu.addEventListener('transitionend', function(e){
        //console.log(e.target, e.propertyName)
        if (e.target == menu && e.propertyName == 'visibility') {
          //console.log('isso!!!')
          var submenus = document.querySelectorAll('.has-submenu .submenu');
          function removeActive(el, index, array) {
            //console.log(el)
            el.classList.remove('active');
          }
          submenus.forEach(removeActive);
        }
      })
    }
  },
  setCarrossel: function() {      
    $('[data-carrossel]').each(function (index, el) {
      var $el = $(el);
      var thumbs = $el.next('[data-carrossel-thumbs]');

      if (thumbs.length > 0) {
          var mirroredThumbs = new Swiper(thumbs.find('.swiper-container'), {
            direction: 'horizontal',
            slidesPerView: 2,
            spaceBetween: 10,
            watchOverflow: true,
            pagination: {
              el: thumbs.find('.swiper-pagination'),
              type: 'bullets',
              clickable: true
            },
            breakpoints: {
              640: {
                slidesPerView: 5,
                spaceBetween: 10,
              }
            }
          });
      }

      var swiper = new Swiper($el.find('.swiper-container'), {
          slidesPerView: $el.data('carrossel-slidesperview-mobile'),
          spaceBetween: parseFloat($el.data('carrossel-spacebetween-mobile')),
          watchOverflow: true,
          simulateTouch: true,
          centeredSlides: false,
          preloadImages: false,
          watchSlidesProgress: true,
          autoHeight: false,
          lazy: true,
          loop: false,
          effect: $el.data('effect'),
          navigation: {
            nextEl: $el.find('.swiper-button-next'),
            prevEl: $el.find('.swiper-button-prev'),
            disabledClass: '-disabled'
          },
          pagination: {
            el: $el.find('.swiper-pagination'),
            type: 'bullets',
            clickable: true
          },
          breakpoints: {
            640: {
              slidesPerView: $el.data('carrossel-slidesperview'),
              spaceBetween: parseFloat($el.data('carrossel-spacebetween')),
              centeredSlides: $el.data('carrossel-centered-slides'),
              loop: $el.data('carrossel-loop'),
            }
          },
          thumbs: {
            swiper: thumbs.length > 0 ? mirroredThumbs : '',
          },
      });
    });     
  },
  setAnimateScroll: function (_refresh) { 
    if (_refresh) {
      AOS.refresh();
    } else {
      AOS.init({
        startEvent: 'DOMContentLoaded',
        once: false,
        mirror: false, 
        offset: 25
      });
    }

    $(window).on('load', function () {
      AOS.refresh();
    });
  },
  setQuantityProduct: function () {
    var quantityInput = document.querySelectorAll('[data-quantity-selector]');

    if (quantityInput) {
      $(document).on('click', '[data-quantity-selector] [data-plus-quantity]', function () {
        var $box = $(this).closest('[data-quantity-selector]');
        var $input = $box.find('#quantity');
        var val = $input.val();

        if (store.validateQuantity(val) && val < $input.data('max-quantity')) {
          var clearNumber = store.getClearNumber(val);
          $input.val(parseInt(clearNumber) + 1);
          var f = $(this).closest('form')[0];
          if (f.classList.contains('form-product')) {
            // update price display
            let inputToCheck = form.querySelector('.inner input:checked');
            if (inputToCheck) inputToCheck.nextElementSibling.click();
          }
        } else {
          if($input.closest('.section-quantity').length > 0){
            $input.closest('.section-quantity').find('.tooltip').addClass('active')

            setTimeout(function() {
              $input.closest('.section-quantity').find('.tooltip').removeClass('active')
            }, 2000);
          }
        }

        if($input.closest('[data-quantity-selector-adicionais]').length > 0){
          template_store.product.menuEspecial.updatePrice()
        }
      });

      $(document).on('click', '[data-quantity-selector] [data-minus-quantity]', function () {
        var $box = $(this).closest('[data-quantity-selector]');
        var $input = $box.find('#quantity');
        var val = $input.val();

        if (store.validateQuantity(val)) {
          var clearNumber = store.getClearNumber(val);
          if ((parseInt(clearNumber) - 1) >= $input.data('min-quantity')) {
            $input.val(parseInt(clearNumber) - 1);
            var f = $(this).closest('form')[0];
            if (f.classList.contains('form-product')) {
              // update price display
              let inputToCheck = form.querySelector('.inner input:checked');
              if (inputToCheck) inputToCheck.nextElementSibling.click();
            }
          } else {
            if($input.closest('.section-quantity').length > 0){
              $input.closest('.section-quantity').find('.tooltip').addClass('active')
  
              setTimeout(function() {
                $input.closest('.section-quantity').find('.tooltip').removeClass('active')
              }, 2000);
            }
          }
        }

        if($input.closest('[data-quantity-selector-adicionais]').length > 0){
          template_store.product.menuEspecial.updatePrice()
        }
      });

      let form = quantityInput[0].closest('form');
      // product page
      if (form.classList.contains('form-product')) {
        // reset quantity value
        let input = form.querySelector('.input-quantity');
        if (input) input.value = input.getAttribute('data-min-quantity');
        // update price display
        let inputToCheck = form.querySelector('.inner input:checked');
        if (inputToCheck) inputToCheck.nextElementSibling.click();
      }
    }
  },
  setBuyProductBlock: function() {
    $(document).on('click', '.product-block [data-size]', function(){
      var sku = $(this).data('sku'),
          max_quantity = $(this).data('max-quantity'),
          product_id = $(this).data('product-id'),
          price = parseFloat($(this).data('price')),
          sale_price = parseFloat($(this).data('sale-price')),
          product_block = $(this).closest('.product-block'),
          available = $(this).data('available');

      // console.log('available', available, product_block.find('[data-action="add-cart"]'))

      product_block.find('[data-size]').removeClass('active');
      $(this).addClass('active');

      product_block.find('[name="quantity"]').data('max-quantity', max_quantity).val(1)
      product_block.find('[name="sku"]').val(sku);

      if(!available){
        product_block.find('[data-action="add-cart"]').text('Esgotado').css('pointer-events', 'none')
      } else {
        product_block.find('[data-action="add-cart"]').text('Comprar').css('pointer-events', 'initial')
      }

      if ((sale_price != '' ) && (sale_price != price)) {
        var htmlPrice =
          '<span class="cmp-price-original-price">' +
          store.formatMoney(price) +
          '</span> ' +
          '<div class="cmp-price-sale-price">' +
          store.formatMoney(sale_price) +
          '</div>';
      } else {
        var htmlPrice = `<div class="cmp-price-price">${store.formatMoney(price)}</div>`;
      }
  
      product_block.find('[data-update-price] .cmp-price-container > div').html(htmlPrice);
    })

    $(document).on('submit', '[data-add-cart="product-block"]', function (e) {
      e.preventDefault();

      var $form = $(this),
          $parent = $form .parent(),
          respValidated = store.validateFormProduct($form),
          $boxResponse = $parent.find('.resp-validate');

      if (respValidated.validated) {
        store.addItem($form, $parent);
      } else {
        store.setRespValidateProduct(respValidated, $form, $boxResponse);
      }
    });

    $(window).on('load', function(){

      setTimeout(function () {
        $('.product-block').each(function(index, el){
          // console.log('oi', $(this).find('[data-size].active').hasClass('inactive'), el)
          if($(this).find('[data-size].active').hasClass('inactive')){
            // console.log('opa', $(this).find('[data-size]:not(.inactive)').first())
            $(this).find('[data-size]:not(.inactive)').first().click()
          }
        })
      }, 2000);      
    })    
  },
  getDiscount: function () {
    const products = document.querySelectorAll("[data-product-wrapper]");

    products.length > 0 && products.forEach(product => {

      const fullPrice = parseFloat(product.querySelector("[data-full-price]")?.getAttribute('data-full-price'));
      const discountPrice = parseFloat(product.querySelector("[data-sale-price]")?.getAttribute('data-sale-price'));

      let discountContainer;
      
      product.parentElement.classList.contains('product-section') 
      ? discountContainer = product.querySelector('.wrapper-slider')
      : discountContainer = product.querySelector(".images a")
      
      if (discountPrice && fullPrice) {
        var percentage = Math.round(((fullPrice - discountPrice) / fullPrice) * 100);
        percentage = percentage.toString().concat("% OFF");
      }

      if (percentage && discountContainer) {

        const flag = document.createElement('div');
        flag.classList.add('flag', 'topo', 'direita')
        flag.innerText = percentage;

        discountContainer.appendChild(flag);
      }
    });
  },
  setSearchModal: function(){
    const container = document.querySelector('header .shortcuts .search')
    if(container){
      const buttonOpenFormModal = container.querySelector('[data-search]')
      const form = container.querySelector('form')
      const inputSearch = form.querySelector('[type=search]')
      const buttonCloseFormModal = form.querySelector('.close')

      function openSearchModal() {
        $(form).toggleClass('active');
      }
      function closeSearchModal() {
        form.classList.remove('active')
      }
      function addEventCloseOnEsc() {
        window.addEventListener('keydown', function(event) {
          if (event.key == 'Escape') closeSearchModal()
        })
      }
      function removeEventCloseOnEsc() {
        window.removeEventListener('keydown', addEventCloseOnEsc)
      }
      if(buttonOpenFormModal){
        buttonOpenFormModal.addEventListener('click', function() {
          openSearchModal()
          inputSearch.focus()
          addEventCloseOnEsc()
        })
      }
      if(buttonCloseFormModal){
        buttonCloseFormModal.addEventListener('click', function() {
          closeSearchModal()
          removeEventCloseOnEsc()
        })
      }        
    }
  },
  quickPreview: function () {
    let quickViews = document.querySelectorAll('[data-action="quick-view"]');
    if (quickViews) {
      quickViews.forEach((quickView) => {
        quickView.addEventListener("click", (event) => {
          event.preventDefault();
          let url = quickView.dataset.url;
          let popupDefault = document.querySelector('[data-component="popup"]');
          let popupDefaultOverlay = document.querySelector('.overlay-popup-quick');
          let iframe = `<iframe src="${url}?version=quickview"></iframe>`;

          popupDefault.querySelector(".content").innerHTML = iframe;
          setTimeout(function () {
            popupDefault.classList.add("active");
            popupDefaultOverlay.classList.add("active");
          }, 400);
        });
      });

      window.addEventListener(
        "message",
        function (e) {
          // console.log('produto-adicionado-quick-view - data:', e.data)
          if (e.data[0] == "produto-adicionado-quick-view") {
            //window.location.href = urlCart;

            var cartResult = e.data[1];

            popupDefault.classList.remove("active");
            popupDefaultOverlay.classList.remove("active");
            window.parent.document.getElementsByTagName(
              "html"
            )[0].style.overflow = "";

            if ($body.width() > 768) {
              if (store.config.addToCartOpenCartpopup) {
                // console.log('opa2')
                template_store.headerCart.show();
              } else {
                setTimeout(function () {
                  window.location.href = urlCart;
                }, 150);
              }
            } else {
              if (store.config.addToCartOpenCartpopupMobile) {
                template_store.headerCart.show();
              } else {
                setTimeout(function () {
                  window.location.href = urlCart;
                }, 150);
              }
            }
          } else if (e.data[0] == "interna-do-produto") {
            window.location.href = e.data[1];
          }
        },
        false
      );

      var qvLink = document.querySelector("[data-qv-link]");
      if (qvLink) {
        qvLink.addEventListener("click", function (e) {
          e.preventDefault();

          window.parent.postMessage(["interna-do-produto", this.href], "*");
        });
      }

      let btnsClosePopup = document.querySelectorAll('[data-action="close-popup"]');
      let popupDefault = document.querySelector('[data-component="popup"]');
      let popupDefaultOverlay = document.querySelector('.overlay-popup-quick');
      
      if (btnsClosePopup) {
        btnsClosePopup.forEach(function(btn){
          btn.addEventListener('click', function(e){
            e.preventDefault();

            popupDefault.classList.remove('active');
            popupDefaultOverlay.classList.remove('active');
          })
        })
      }
    }
  },
  waitElement: function (selector) {
    return new Promise(function (resolve) {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }
      var observer = new MutationObserver(function(mutations) {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  },
  setMobileIcons: function () {
    this.waitElement('#menu-mobile.mm-menu [data-icons]').then((swiperEl) => {
      setTimeout(() => {
        let swiper = new Swiper(swiperEl, {
          slidesPerView: 'auto',
          spaceBetween: 0,
          watchOverflow: false,
          watchSlidesProgress: true,
          // simulateTouch: true
        });
        swiper.update();
      }, 4000);
    });
  },
  loadPrices: function () {
    const _this = this;
    $(window).on('load', function(){
      store.getPriceProd();
      setTimeout(() => { _this.getDiscount() }, 2500)
    })
  },
  setSubmit: function () {
    const _this = this;

    // ENVIO DE FORMULÁRIOS
    $('[data-webform]').on('submit', function (event) {
      event.preventDefault();
      var $form = $(this);
      var vnda = $(this).find('[name="vnda"]');

      if (vnda.length > 0) {
        if (vnda.val() != '') {
          console.info('ROBOT DETECTED');
          return false;
        }
      }

      store.submitForm($form);
    });
  },
  home: {
    setFullbanner: function () {      
      $('[data-fullbanner]').each(function (index, el) {
        var $el = $(el);
  
        var swiper = new Swiper($el.find('.swiper-container'), {
          slidesPerView: 1,
          spaceBetween: 0,
          watchOverflow: true,
          autoHeight: true,
          autoplay: {
            delay: 4000,
          },
          navigation: {
            nextEl: $(el).find('.swiper-button-next'),
            prevEl: $(el).find('.swiper-button-prev'),
            disabledClass: '-disabled'
          },
          pagination: {
            el: $(el).find('.swiper-pagination'),
            type: 'bullets',
            clickable: true
          },        
        });
      });     
    },
    init: function () {
      template_store.home.setFullbanner();
      template_store.setCarrossel()
      template_store.setQuantityProduct();
      template_store.setBuyProductBlock();
    }
  },
  product: {
    // Aqui ficam os js do transacional
    $sectionProduct: $('[data-section-product]'),
    $wrapperImages: $('[data-section-product]').find('[data-product-images]'),
    $sliderProduct: $('[data-section-product]').find('[data-product-images] [data-main-slider]'),
    $thumbsProduct: $('[data-section-product]').find('[data-product-images] [data-slider-thumbs]'),
    sliderProduct: [],
    /* 
      Exibe as primeiras imagens do produto
      ** Importante para quando houver imagem por sku **
    */
    showFirstImages: function () {
      var _this = this;
      var variants = vnda.variants.all();
      var $imagesWithoutSkus = _this.$sliderProduct.find('.image-without-sku');
      var $thumbsWithoutSkus = _this.$thumbsProduct.find('.image-without-sku');

      if ($imagesWithoutSkus.length > 0) {
        /* 
          Todas as imagens sem skus são exibidas sempre
        */

        $imagesWithoutSkus.removeClass('inactive').addClass('active');

        $thumbsWithoutSkus.removeClass('inactive').addClass('active');
      } else {
        /* 
          Percorre todas as variantes
        */
        for (var i = 0; i < variants.length; i++) {
          if (variants[i].main) {
            // console.info(variants[i]);

            // Procura a imagem que contém o sku e a exibe
            _this.$sliderProduct
              .find('[data-image][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');

            _this.$thumbsProduct
              .find('[data-image-thumb][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');
          }
        }
      }
    },
    setGallery: function () {
      var _this = this;
      if($('.swiper-container[data-slider-thumbs]').length > 0){
        var galleryThumbs = new Swiper('.swiper-container[data-slider-thumbs]', {
          direction: 'vertical',
          spaceBetween: 10,
          slidesPerView: 6,
          watchOverflow: true,
          navigation: {
            nextEl: $('.swiper-container[data-slider-thumbs]').closest('.wrapper-thumbs').find('.swiper-button-next'),
            prevEl: $('.swiper-container[data-slider-thumbs]').closest('.wrapper-thumbs').find('.swiper-button-prev'),
          },
        });
      }

      var gallery = new Swiper(_this.$sliderProduct, {
        direction: 'horizontal',
        slidesPerView: 1,
        watchOverflow: true,
        thumbs: {
          swiper: galleryThumbs,
        },
      });

      this.sliderProduct.push(gallery);
      this.sliderProduct.push(galleryThumbs);

      $(document).on('click', '[data-image-thumb]:visible', function () {
        gallery.slideTo($(this).index('[data-image-thumb]:visible'));
      });
    },
    submitNotify: function () {
      $(document).on('submit', '[data-form-notify]', function (event) {
        event.preventDefault();
        template_store.submitFormNotify($(this));
      });
    },
    popupSizes: function(){
      $('.popup-sizes .close').on('click', function(){
        $('.overlay-popup-sizes').removeClass('active')
        $('.popup-sizes').removeClass('active')
      })

      $('.overlay-popup-sizes').on('click', function(){
        $('.overlay-popup-sizes').removeClass('active')
        $('.popup-sizes').removeClass('active')
      })

      $('.open-popup-sizes').on('click', function(){
        $('.overlay-popup-sizes').addClass('active')
        $('.popup-sizes').addClass('active')
      })
    },
    menuEspecial: {
      updatePrice: function(){
        var $productInfosBox = $('[data-product-infos]'),
            $productPriceBox = $productInfosBox.find('[data-update-price]');

        var originalPrice = 0,
            originalSalePrice = 0,
            originalInstallmentsNumber = parseInt($productPriceBox.data('installments-number')),
            originalInstallmentsPrice = parseFloat($productPriceBox.data('installments-price'));

        // quando temos produtos personalizados no menu especial, devemos somar o preço do produto principal no preço mostrado
        if($('.section-personalizacao').length > 0 || $('[data-enviar-produto-principal]').length > 0){
          originalPrice = parseFloat($productPriceBox.data('price'));
          originalSalePrice = parseFloat($productPriceBox.data('sale-price'));
        }

        var newPrice = originalPrice,
            newSalePrice = originalSalePrice,
            newInstallmentsNumber = originalInstallmentsNumber,
            newInstallmentsPrice = originalInstallmentsPrice;

        $('[data-customization-price]').each(function (index, el) {
          if($(this).is(':checked')){
            var customizationPrice = $(this).data('customization-price');

            newPrice += parseFloat(customizationPrice);
            newSalePrice += parseFloat(customizationPrice);
            newInstallmentsPrice = newSalePrice / newInstallmentsNumber;
          }          
        })    

        $('[data-product-include]').each(function (index, el) {
          if($(this).is(':checked')){
            var customizationPrice = $(this).data('include-price');

            newPrice += parseFloat(customizationPrice);
            newSalePrice += parseFloat(customizationPrice);
            newInstallmentsPrice = newSalePrice / newInstallmentsNumber;
          }          
        })

        $('[data-additional-price]').each(function (index, el) {
          if($(this).find('[data-quantity-selector]').hasClass('adicionado')){
            var additionalPrice = $(this).data('additional-price');

            newPrice += parseFloat(additionalPrice) * $(this).find('[data-quantity-selector]').find('input[name="quantity-adicionais"]').val();
            newSalePrice += parseFloat(additionalPrice) * $(this).find('[data-quantity-selector]').find('input[name="quantity-adicionais"]').val();
            newInstallmentsPrice = newSalePrice / newInstallmentsNumber;
          }          
        })

        if (newSalePrice != newPrice) {
          var htmlPrice =
            '<span class="cmp-price-original-price">' +
              store.formatMoney(newPrice) +
            '</span> ' +
            '<div class="cmp-price-sale-price">' +
              store.formatMoney(newSalePrice) +
            '</div>';
        } else {
          var htmlPrice = `<div class="cmp-price-price">${store.formatMoney(newPrice)}</div>`;
        }
    
        htmlPrice += `<div class="cmp-price-installments"><span> ${newInstallmentsNumber}x de ${store.formatMoney(newInstallmentsPrice)}</span><div>`;
    
        // console.log('htmlPrice', htmlPrice)
    
        $('[data-section-product] [data-update-price] .cmp-price-container > div').html(htmlPrice);
      },
      addItemsCombo: function (comboItems) {
        var data = {
          items: comboItems
        }
        
        var form_data = JSON.stringify(data);
      
        return $.ajax({
          url: '/carrinho/adicionar/kit',
          type: 'POST',
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          xhrFields: {
            withCredentials: true
          },
          cache: false,
          data: form_data
        });
      },
      submitForm: function(){
        $(document).on('submit', '[data-form-product-menu-especial]', function (event) {
          event.preventDefault();
  
          var comboItems = [];   

          if($('.section-personalizacao').length > 0 || $('[data-enviar-produto-principal]').length > 0){
            var item = {
              sku: $('[data-form-product-menu-especial] [name="sku"]').val(),
              quantity: parseInt($('[data-quantity-selector-pessoas]').find('[name="quantity"]').val(), 10)
            }  
  
            if($('[data-customization-price]:checked').length > 0){
              $('[data-customization-price]:checked').each(function(index, el){
                // console.log('opa', el, $(el).attr('name'))
                item[$(el).attr('name')] = $(el).val();
              })
            }
              
            // console.log('item', item)
    
            comboItems.push(item);  
          }
          
          $('[data-product-include]:checked').each(function(index, el){
            var item = {
              sku: $(el).data('product-include'),
              quantity: $('[data-quantity-selector-pessoas]').find('[name="quantity"').val(),
            }

            item[$(el).next().find('[data-main-product]').attr('name')] = $(el).next().find('[data-main-product]').val();
  
            comboItems.push(item);       
          });

          $('[data-product-additional]').each(function(index, el){
            if($(el).find('.adicionado').length > 0){
              var item = {
                sku: $(el).data('product-additional'),
                quantity: parseInt($(this).find('[name="quantity-adicionais"]').val(), 10),
              }

              item[$(el).find('[data-main-product]').attr('name')] = $(el).find('[data-main-product]').val();
    
              comboItems.push(item); 
            }            
          });

          // console.log('comboItems', comboItems)
          
          if (comboItems.length > 0){
            var result = template_store.product.menuEspecial.addItemsCombo(comboItems).then(function(response) {
              return response
            }, Promise.resolve([]));

            result.then(function(results) {
              template_store.headerCart.loadCart(results);
            }).promise().done(function() {
              // console.log('opa', document.location.search)
              if (document.location.search.includes("quickview")) {
                // console.log('oie')
                var parentWindow = window.parent;
                parentWindow.postMessage(["produto-adicionado-quick-view"], "*");
                // console.log('oie2')
              } else {
                if ($body.width() > 768) {
                  if (store.config.addToCartOpenCartpopup) {
                    // console.log('opa2')
                    template_store.headerCart.show();
                  } else {
                    setTimeout(function () {
                      window.location.href = urlCart;
                    }, 150);
                  }
                } else {
                  if (store.config.addToCartOpenCartpopupMobile) {
                    template_store.headerCart.show();
                  } else {
                    setTimeout(function () {
                      window.location.href = urlCart;
                    }, 150);
                  }
                }
              }
            })
          }
        });
      },
      popupConfirm: function(){
        $('.popup-confirm .close').on('click', function(){
          $('.overlay-popup-confirm').removeClass('active')
          $('.popup-confirm').removeClass('active')
        })

        $('.overlay-popup-confirm').on('click', function(){
          $('.overlay-popup-confirm').removeClass('active')
          $('.popup-confirm').removeClass('active')
        })

        $('[data-open-popup-confirm]').on('click', function(e){
          e.preventDefault();

          var html_customization = '';

          $('[data-product-include]:checked').each(function(index, el){
            html_customization +=  '' +
                                '<p>' + $('[data-quantity-selector-pessoas]').find('[name="quantity"').val() + 'x ' + 
                                  $(el).data('product-include-name') + 
                                  '<small>' + store.formatMoney(parseFloat($(el).data('include-price'))) + ' cada</small>' +
                                '</p>';
                        
          });

          if(html_customization != ''){
            $('.popup-confirm .includes .items').html(html_customization)
          }

          if (html_customization == ''){
            $('.popup-confirm .includes').hide()
          } else{
            $('.popup-confirm .includes .items').html(html_customization)
            $('.popup-confirm .includes').show()
          }

          var html_additional = '';

          $('[data-product-additional]').each(function(index, el){
            if($(el).find('.adicionado').length > 0){
              html_additional +=  '' +
                                  '<p>' + $(this).find('[name="quantity-adicionais"]').val() + 'x ' + 
                                    $(el).data('product-additional-name') + 
                                    '<small>' + store.formatMoney(parseFloat($(el).data('additional-price'))) + ' cada</small>' +
                                  '</p>';
            }            
          });

          if (html_additional == ''){
            $('.popup-confirm .additionals').hide()
          } else{
            $('.popup-confirm .additionals .items').html(html_additional)
            $('.popup-confirm .additionals').show()
          }

          $('.overlay-popup-confirm').addClass('active')
          $('.popup-confirm').addClass('active')
        })
      },   
      init: function(){
        template_store.product.menuEspecial.submitForm();
        template_store.product.menuEspecial.popupConfirm();

        $('[data-quantity-selector-adicionais] .add').on('click', function(){
          $(this).closest('[data-quantity-selector-adicionais]').addClass('adicionado')
          template_store.product.menuEspecial.updatePrice()
        })
  
        $('[data-quantity-selector-adicionais] .remove').on('click', function(e){
          e.preventDefault()
          // console.log('oi', $(this).closest('[data-quantity-selector-adicionais]'))
          $(this).closest('[data-quantity-selector-adicionais]').removeClass('adicionado')
          template_store.product.menuEspecial.updatePrice()
        })

        $('[data-product-include]').on('change', function(){
          template_store.product.menuEspecial.updatePrice()
        })   
        
      }
    },
    init: function () {
      var _this = this;

      _this.showFirstImages();
      _this.setGallery();
      _this.submitNotify();
      _this.popupSizes();
      _this.menuEspecial.init();
      template_store.setCarrossel()
      template_store.setQuantityProduct();
      template_store.setBuyProductBlock();

      $(document).on('submit', store.config.selectorFormProduct, function (event) {
        event.preventDefault();

        var $form = $(this);
        var $parent = $form.closest(store.config.selectorWrapperProduct);

        var $btnComprar = $parent.find(store.config.selectorBtnBuy);

        var respValidated = store.validateFormProduct($form);
        var $boxResponse = $parent.find('.resp-validate');

        if (respValidated.validated) {
          store.addItem($form, $parent);
        } else {
          // console.info('não foi');
          store.setRespValidateProduct(respValidated, $form, $boxResponse);
        }
      });
    },
  },
  tag: {
    clearFilters: function () {
      $(document).ready(function() {
        if(document.location.search.length) {
          $('.clear--filters').css('opacity', 1);
        } else {
          $('.clear--filters').css('opacity', 0);
        }
      }); 
    },
    openFilter: function () {
      $('.open-filter').on('click', function(){
        $('#filtros form').addClass('open')
        $('body').css('overflow', 'hidden')
      })
    },
    closeFilter: function () {
      $('.close-filter').on('click', function(){
        $('#filtros form').removeClass('open')
        $('body').css('overflow-y', 'auto')
      })
    },
    collapseFilter: function () {
      $('.filtro strong').on('click', function(){
        if($(window).width() < 768){
          $(this).toggleClass('open')
          $(this).closest('.filtro').toggleClass('open')
          if($(this).closest('.filtro').find('ul').height() > 0){
            $(this).closest('.filtro').find('ul').height(0)
          } else {
            $(this).closest('.filtro').find('ul').height($(this).closest('.filtro').find('ul').find('div').height() + 15)
          }
        }
      })
    },
    init: function () {
      template_store.tag.clearFilters();
      template_store.tag.openFilter();
      template_store.tag.closeFilter();
      template_store.tag.collapseFilter();
      template_store.setQuantityProduct();
      template_store.setBuyProductBlock();

      if($(window).width() > 767){
        $('#filter-form input').on('change', function(e){
          $(this).closest('form').trigger('submit');
        })
      }

      if(window.location.href.indexOf("ecommerce") > -1) {
        template_store.home.init();
      }

    },
  },
  login: {
    init: function () {
      $('[data-content-login]').each(function (index, el) {
        // Animação
        $(el).on('click', '[data-forget-password]', function (event) {
          event.preventDefault();
          $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
          $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('click', '[data-forget-password-back]', function (event) {
          event.preventDefault();
          $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
          $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('submit', '[data-form-login]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateLogin($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });

        $(el).on('submit', '[data-form-register]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRegister($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });

        $(el).on('submit', '[data-form-recover]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRecover($form);

          if (respValidated.validated) {
            store.login.recoverPassord($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });
      });
    },
  },
  page: {
    setAccordions: function(){
      $('.accordion h2').on('click', function(){
        var accordion = $(this).closest('.accordion')

        accordion.toggleClass('active')

        if (accordion.height() < 50){
          accordion.height(accordion.find('div').height() + 15)
        } else {
          accordion.height(48)
        }
      })
    },
    smoothScrollAnchors: function () {
      const mirroredElements = document.querySelectorAll('.mirrored');

      mirroredElements.length > 0 && mirroredElements.forEach(element => {
        const id = element.getAttribute('id');
        const elementToScroll = document.getElementById(id);

        if (elementToScroll) {

          const scrollConfigs = {
            top: elementToScroll.offsetTop,
            left: 0,
            behavior: 'smooth'
          };

          if (window.location.href.includes(id)) {
            setTimeout(function () {
              window.scroll(scrollConfigs);
            }, 500);
          }
        }
      })
    },
    init: function(){
      template_store.home.setFullbanner();
      template_store.setCarrossel();
      template_store.page.setAccordions();

      this.smoothScrollAnchors();

      $('[data-mapa]').on('click', function(e){
        e.preventDefault();

        let url = $(this).attr('href')

        $('.map iframe').attr('src', url)
      })
    }
  },
  init: function () {
    const _this = this;
    
    store.getCartCount();

    _this.headerCart.initDrawer();
    _this.headerCart.productsMinQuantity();
    
    _this.setAnimateScroll();
    _this.setSearchModal();
    _this.setMenuMobile();
    _this.setMobileIcons();

    template_store.quickPreview();    
    
    _this.loadPrices();
    _this.setSubmit();
  },
};

window.addEventListener('DOMContentLoaded', () => {
  template_store.init();

  if (page == 'home') {
    template_store.home.init();
  }

  if (page == 'product') {
    template_store.product.init();
  }

  if (page == 'tag' || page == 'search') {
    template_store.tag.init();
  }

  if (page == 'login') {
    template_store.login.init();
  }

  if (page == 'page') {
    template_store.page.init();
  }
});

$(window).on("load", function () {
  $("body").removeClass("window-loading");
});

console.log(
  '%cVnda - Tecnologia em Ecommerce',
  'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;'
);
